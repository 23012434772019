import * as React from "react";
import Layout from "../components/layout";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import { StaticQuery, graphql } from "gatsby";
import ArticleTile from "../components/article-tile";

import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Image from "../components/image";

const ProjectsPage = () => {
  const intl = useIntl();
  return (
    <>
      <StaticQuery
        query={graphql`
          query utdf24 {
            allWpPage(sort: { order: ASC, fields: menuOrder }) {
              edges {
                node {
                  title
                  slug
                  content
                  menuOrder
                  page {
                    pageGroup
                    language
                    shortDescription
                  }
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const pages = data.allWpPage.edges;
          const projects = pages.filter(
            (i) =>
              i.node.page.pageGroup === "utdf2024" &&
              i.node.page.language === intl.locale
          );

          if (!projects[projects.length - 1].node.menuOrder) {
            const last = projects.pop();
            projects.unshift(last);
          }
          return (
            <Layout>
              <main className="pb-10 overflow-hidden">
                {/* <div className="absolute z-[-2]  h-[100vh] top-[-88px] left-[-20px]  w-[calc(100%+20px)]">
                  <Image
                    src="kapusta.png"
                    className="w-full h-full"
                    objectFit="cover"
                    objectPosition="bottom center"
                    alt=""
                  />
                </div>
                <div className="absolute z-[-1] top-[calc(100vh-248px)] w-[calc(100%+20px)] h-40 from-transparent to-utdf bg-gradient-to-b left-[-20px] " /> */}

                <header className="px-4 py-10 sm:pt-[7.5rem]">
                  <h1 className="flex flex-wrap items-center justify-between gap-4 big-heading ">
                    Electrum Up To Date Festival 2024
                    <Link
                      className="text-xl font-normal lg:text-2xl"
                      to="/timetable"
                    >
                      TIMETABLE &rarr;
                    </Link>
                  </h1>
                </header>
                <div className="grid items-start grid-cols-1 gap-8 px-8 mx-auto gap-y-12 md:grid-cols-2 lg:grid-cols-3 max-w-7xl lg:py-8">
                  {projects.map((project, i) => (
                    <ArticleTile
                      key={i}
                      link={project.node.slug}
                      title={project.node.title}
                      gatsbyImage={getImage(
                        project.node?.featuredImage.node.localFile
                      )}
                      description={project.node.page.shortDescription}
                    />
                  ))}

                  <article className="group">
                    <a
                      href="https://forms.gle/s4XJeEKyYyGXx2qV7"
                      target="_blank"
                    >
                      <div className="space-y-4">
                        <div className="aspect-[3/2]  w-full overflow-hidden bg-white group relative">
                          <StaticImage
                            src="../images/press.png"
                            alt=" "
                            className="w-full h-full"
                            objectFit="cover"
                          />
                          <span class="transition-all inset-0 absolute group-hover:opacity-100 opacity-0 grid place-content-center backdrop-blur-md">
                            <span className="inline-block px-4 py-2 font-medium text-black rounded-lg bg-utdf-secondary">
                              {intl.formatMessage({ id: "More" })} &rarr;
                            </span>{" "}
                          </span>
                        </div>
                        <div className="w-full space-y-4">
                          <h2 className="text-2xl group-hover:underline ">
                            {intl.formatMessage({ id: "Accreditation" })}
                          </h2>
                        </div>
                      </div>
                    </a>
                  </article>

                  <article className="group">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSeiKNTUUGrjfYz4exg-SC8PVPaUQmya8PG9NI9hSEvfiVeDkg/viewform"
                      target="_blank"
                    >
                      <div className="space-y-4">
                        <div className="aspect-[3/2]  w-full overflow-hidden bg-white group relative">
                          <StaticImage
                            src="../images/industry.jpg"
                            alt=" "
                            className="w-full h-full"
                            objectFit="cover"
                          />
                          <span class="transition-all inset-0 absolute group-hover:opacity-100 opacity-0 grid place-content-center backdrop-blur-md">
                            <span className="inline-block px-4 py-2 font-medium text-black rounded-lg bg-utdf-secondary">
                              {intl.formatMessage({ id: "More" })} &rarr;
                            </span>{" "}
                          </span>
                        </div>
                        <div className="w-full space-y-4">
                          <h2 className="text-2xl group-hover:underline ">
                            Industry Pass
                          </h2>
                        </div>
                      </div>
                    </a>
                  </article>

                  {/* 
                  <article className="group">
                    <a
                      href="https://forms.gle/s4XJeEKyYyGXx2qV7"
                      target="_blank"
                    >
                      <div className="space-y-4">
                        <div className="aspect-[3/2]  w-full overflow-hidden bg-white group relative">
                          <StaticImage
                            src="../images/press.png"
                            alt=" "
                            className="w-full h-full"
                            objectFit="cover"
                          />
                          <span class="transition-all inset-0 absolute group-hover:opacity-100 opacity-0 grid place-content-center backdrop-blur-md">
                            <span className="inline-block px-4 py-2 font-medium text-black rounded-lg bg-utdf-secondary">
                              {intl.formatMessage({ id: "More" })} &rarr;
                            </span>{" "}
                          </span>
                        </div>
                        <div className="w-full space-y-4">
                          <h2 className="text-2xl group-hover:underline ">
                            {intl.formatMessage({ id: "Accreditation" })}
                          </h2>
                        </div>
                      </div>
                    </a>
                  </article>

                  <article className="group">
                    <a
                      href="https://forms.gle/s4XJeEKyYyGXx2qV7"
                      target="_blank"
                    >
                      <div className="space-y-4">
                        <div className="aspect-[3/2]  w-full overflow-hidden bg-white group relative">
                          <StaticImage
                            src="../images/industry.jpg"
                            alt=" "
                            className="w-full h-full"
                            objectFit="cover"
                          />
                          <span class="transition-all inset-0 absolute group-hover:opacity-100 opacity-0 grid place-content-center backdrop-blur-md">
                            <span className="inline-block px-4 py-2 font-medium text-black rounded-lg bg-utdf-secondary">
                              {intl.formatMessage({ id: "More" })} &rarr;
                            </span>{" "}
                          </span>
                        </div>
                        <div className="w-full space-y-4">
                          <h2 className="text-2xl group-hover:underline ">
                            {intl.formatMessage({ id: "Accreditation" })}
                          </h2>
                        </div>
                      </div>
                    </a>
                  </article> */}

                  {/* {
                    <article className="group">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd8M-Jfc6ul654vH-BdzrjzNzwdYbjCR3qm8HWWsd-Y7rDszA/viewform"
                        target="_blank"
                      >
                        <div
                          className={`flex flex-wrap lg:flex-nowrap flex-col-reverse lg:flex-row gap-6 md:gap-11 items-center ${
                            projects.length % 2 === 0
                              ? "lg:flex-row-reverse"
                              : null
                          }`}
                        >
                          <div className="w-full lg:basis-[23.5rem] lg:shrink-0 space-y-4">
                            <h2 className="text-2xl font-gambetta lg:text-5xl">
                              {intl.formatMessage({ id: "Accreditation" })}
                            </h2>
                            <span className="inline-block px-4 py-2 font-medium text-black rounded-lg bg-utdf-secondary">
                              {intl.formatMessage({ id: "More" })} &rarr;
                            </span>
                          </div>
                          <div className="aspect-[3/2] w-full overflow-hidden bg-white">
                            <StaticImage
                              src="../images/press.png"
                              alt=" "
                              className="w-full h-full"
                              objectFit="cover"
                            />
                          </div>
                        </div>
                      </a>
                    </article>
                  }
                    <a
                      className="inline-block px-4 py-2 font-medium text-black rounded-lg bg-utdf-secondary"
                      href="/docs/regulamin-utdf.pdf"
                    >
                      Regulamin &darr;
                    </a>
 */}
                </div>
                <div className="flex flex-wrap justify-end gap-4 px-8 mx-auto max-w-7xl">
                  <a
                    className="inline-block px-4 py-2 font-medium text-black rounded-lg bg-utdf-secondary"
                    href="/docs/oswiadczenie-2024.pdf"
                  >
                    Oświadczenie opiekuna &darr;
                  </a>
                  <a
                    className="inline-block px-4 py-2 font-medium text-black rounded-lg bg-utdf-secondary"
                    href="/docs/regulamin-2024.pdf"
                  >
                    Regulamin &darr;
                  </a>
                </div>
              </main>
            </Layout>
          );
        }}
      ></StaticQuery>
    </>
  );
};

export default ProjectsPage;
